import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import DurakLogo from '../../components/durak-logo/durak-logo.component';

import { getUser, userVisit } from '../../services/user-req';

import {
  LoadingLine,
  LoadingLineContainer,
  LoadingPageBg,
  LoadingPageContainer,
  LoadingText,
} from './loading-page.styles';
import { setUser } from '../../store/user/user.slice';
import { useTonWallet } from '@tonconnect/ui-react';

const LoadingPage = () => {
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const wallet = useTonWallet();

  useEffect(() => {
    (async () => {
      const token = searchParams.get('token');
      const lobbyid = searchParams.get('lobbyid');
      if (!token) {
        nav('/error');
      } else {
        try {
          const user = await getUser(token);

          if (!user) {
            nav('/error');
          } else {
            dispatch(setUser(user));
            const os = window.navigator.userAgent;

            await userVisit(token, { os: os, ip: `0.0.0` });
            if (user.current_game) {
              nav(`/lobby/${user.current_game}`);
            } else if (!user.wallet || !wallet) {
              nav('/rules');
              return null;
            } else if (lobbyid) {
              nav(`/find/?lobbyid=${lobbyid}`);
            } else nav('/home');
            // else nav('rules')
          }
        } catch {
          nav('/error');
        }
      }
    })();
  }, []);

  return (
    <LoadingPageBg>
      <LoadingPageContainer>
        <DurakLogo />
        <LoadingText>Готовимся к игре века...</LoadingText>
        <LoadingLineContainer>
          <LoadingLine />
        </LoadingLineContainer>
      </LoadingPageContainer>
    </LoadingPageBg>
  );
};

export default LoadingPage;

export const Loading = () => {
  return (
    <LoadingPageBg>
      <LoadingPageContainer>
        <DurakLogo />
        <LoadingText>Готовимся к игре века...</LoadingText>
        <LoadingLineContainer>
          <LoadingLine />
        </LoadingLineContainer>
      </LoadingPageContainer>
    </LoadingPageBg>
  );
};
