import { IUser, IUserInfo } from '../types/user.interface';

// const SERVER_URI = 'http://localhost:8000/api';
const SERVER_URI = '/api';

export const getUser = async (token: string): Promise<IUser | null> => {
  try {
    const response = await fetch(`${SERVER_URI}/user/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const user = await response.json();
    return user;
  } catch (error) {
    console.error('Error in getUser:', error);
    return null;
  }
};

export const userVisit = async (
  token: string,
  userInfo: { os: string; ip: string }
): Promise<IUser | null> => {
  try {
    const response = await fetch(`${SERVER_URI}/user/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userInfo),
    });

    const user = await response.json();
    return user;
  } catch (error) {
    console.error('Error in userVisit:', error);
    return null;
  }
};

export const userwalletUpdate = async (
  token: string,
  address: string
): Promise<IUser | null> => {
  try {
    const response = await fetch(`${SERVER_URI}/user/wallet/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ wallet: address }),
    });

    const user = await response.json();
    return user;
  } catch (error) {
    console.error('Error in userVisit:', error);
    return null;
  }
};

export const getUsers = async (
  token: string,
  usersId: number[]
): Promise<IUserInfo[] | null> => {
  try {
    const response = await fetch(`${SERVER_URI}/user/users/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(usersId),
    });

    const users = await response.json();
    return users;
  } catch (error) {
    console.error('Error in getUsers:', error);
    return null;
  }
};
