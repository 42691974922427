import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { Loading } from './loading-page/loading-page.component';

const HomePageLazy = lazy(() => import('./home-page/home-page.component'));
const CreatePageLazy = lazy(() => import('./create-page/create-page.comonent'));
const FindPageLazy = lazy(() => import('./find-page/find-page.component'));
const RulesPageLazy = lazy(() => import('./rules-page/rules-page.component'));
const LobbyPageLazy = lazy(() => import('./lobby-page/lobby-page.component'));
const ProfilePageLazy = lazy(
  () => import('./profile-page/profile-page.component')
);
const ConnectPageLazy = lazy(
  () => import('./connect-page/connect-page.component')
);
const LoadingPageLazy = lazy(
  () => import('./loading-page/loading-page.component')
);

function AppLoyout() {
  return (
    <>
      <Routes>
        <Route
          path='/home'
          element={
            <Suspense fallback={<Loading />}>
              <HomePageLazy />
            </Suspense>
          }
        />
        <Route
          path='/create'
          element={
            <Suspense fallback={<Loading />}>
              <CreatePageLazy />
            </Suspense>
          }
        />
        <Route
          path='/find'
          element={
            <Suspense fallback={<Loading />}>
              <FindPageLazy />
            </Suspense>
          }
        />
        <Route
          path='/'
          element={
            <Suspense fallback={<Loading />}>
              <LoadingPageLazy />
            </Suspense>
          }
        />
        <Route
          path='/connect'
          element={
            <Suspense fallback={<Loading />}>
              <ConnectPageLazy />
            </Suspense>
          }
        />
        <Route
          path='/rules'
          element={
            <Suspense fallback={<Loading />}>
              <RulesPageLazy />
            </Suspense>
          }
        />
        <Route
          path='/profile'
          element={
            <Suspense fallback={<Loading />}>
              <ProfilePageLazy />
            </Suspense>
          }
        />
        <Route
          path='/lobby/:lobbyId'
          element={
            <Suspense fallback={<Loading />}>
              <LobbyPageLazy />
            </Suspense>
          }
        />
        <Route
          path='/error'
          element={
            <Suspense fallback={<Loading />}>
              <div>
                <h1>Error</h1>
              </div>
            </Suspense>
          }
        />
      </Routes>
    </>
  );
}

export default AppLoyout;
