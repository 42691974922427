import styled, { keyframes } from 'styled-components';

import LoadingBg from '../../assets/bg/loading_bg.png';

export const LoadingPageBg = styled.div`
  background-image: url(${LoadingBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
`;

export const LoadingPageContainer = styled.div`
  padding: 50px 8vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  min-height: 90%;
  justify-content: space-between;
`;

export const LoadingText = styled.h1`
  font-size: 64px;
  margin-top: -170px;
  font-weight: 700;
  line-height: 76.8px;
  letter-spacing: 0.44px;
  text-align: left;
  background: -webkit-linear-gradient(-90deg, #ffffff 0%, #8e8e8e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const loadingAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

export const LoadingLineContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.45);
  position: relative;
  overflow: hidden;
`;

export const LoadingLine = styled.div`
  height: 100%;
  border-radius: 8px;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${loadingAnimation} 10s infinite ease-in-out;
`;
