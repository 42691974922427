import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../root-reducer';
import { IUserState } from './user.slice';
import {
  formatNumberWithSpace,
  formatWithPereod,
} from '../../utils/number-transform';

const selectAppState = (state: RootState): IUserState => state.user;

export const selectUser = createSelector([selectAppState], (user) => user.user);

export const selectToken = createSelector(
  [selectAppState],
  (user) => user.user?.token || []
);

export const selectUserId = createSelector(
  [selectAppState],
  (user) => user.user?.id
);

export const selectUserImg = createSelector(
  [selectAppState],
  (user) => user.user?.profile_image
);

export const selectUserName = createSelector(
  [selectAppState],
  (user) => user.user?.username
);

export const selectUserInfo = createSelector([selectAppState], (user) => ({
  username: user.user?.username,
  winrate: formatWithPereod(
    ((user.user?.game_win || 0) / (user.user?.game_played || 1)) * 100
  ),
  winUsdt: formatNumberWithSpace(user.user?.win_usdt || 0),
  userImg: user.user?.profile_image,
}));

// export const selectIsAdmin = createSelector([selectUser], (user) =>
//   Boolean(user?.is_admin)
// );

// export const selectUserId = createSelector(
//   [selectUser],
//   (user) => user?._id || ''
// );

// export const selectUserTg = createSelector(
//   [selectAppState],
//   (user): { username: string; user_photo: string } => ({
//     username: user.username || '',
//     user_photo: user.user_photo || '',
//   })
// );
