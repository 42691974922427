import styled from 'styled-components';

export const DurakLogoContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: flex-end;
`;

export const DurakLogoIcon = styled.div`
  background: rgba(255, 73, 73, 1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const DurakLogoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const DurakText = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  letter-spacing: 0.44px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: -3px;
`;

export const DurakSecondText = styled.span`
  font-family: Cygre;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: 0.44px;
  text-align: left;
  color: rgba(117, 117, 117, 1);
  margin-bottom: 2px;
`;
