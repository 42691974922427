import React from 'react';
import {
  DurakLogoContainer,
  DurakLogoIcon,
  DurakLogoTextContainer,
  DurakSecondText,
  DurakText,
} from './durak-logo.styles';

const DurakLogo = () => {
  return (
    <DurakLogoContainer>
      <DurakLogoIcon></DurakLogoIcon>
      <DurakLogoTextContainer>
        <DurakText>durak</DurakText>
        <DurakSecondText>online</DurakSecondText>
      </DurakLogoTextContainer>
    </DurakLogoContainer>
  );
};

export default DurakLogo;
