import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

body {
  background: black;
  margin: 0;
  color: white;
  font-family: Cygre, sans-serif;

  .hidden {
    display: none;
  }
}
`;
