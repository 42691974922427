import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '../../types/user.interface';

export interface IUserState {
  user: IUser | null;
}

const USER_STATE: IUserState = {
  user: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: USER_STATE,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export const userReducer = userSlice.reducer;
