export function formatWithPereod(num: number): string {
  const rounded = num.toFixed(1);

  return rounded.replace('.', ',');
}

export function formatNumberWithSpace(num: number): string {
  const rounded = Math.round(num);

  return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
