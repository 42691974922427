import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectUser } from './store/user/user.selector';
import { GlobalStyle } from './global.styles';
import AppLoyout from './routes/apployout';

function App() {
  const nav = useNavigate();
  const user = useSelector(selectUser);

  return (
    <div>
      <GlobalStyle />
      <AppLoyout />
    </div>
  );
}

export default App;
